export const TRANSL = { 
    TICKET_WARRANTY: 'TICKET_WARRANTY',
    TICKETS_WARRANTY: 'TICKETS_WARRANTY',
    TICKET_PRECLAIM: 'TICKET_PRECLAIM',
    TICKETS_PRECLAIM: 'TICKETS_PRECLAIM',
    TICKETS_MANDATORY_ASSET: 'TICKETS_MANDATORY_ASSET',
    TICKETS_MANDATORY_MAIL: 'TICKETS_MANDATORY_MAIL',
    TICKETS_ERROR_MAIL: 'TICKETS_ERROR_MAIL',
    TICKET_WARRANTY_NAVBAR: 'TICKET_WARRANTY_NAVBAR',
	TICKET_PRECLAIM_NAVBAR: 'TICKET_PRECLAIM_NAVBAR',

    TICKET_OPENED: "TICKET_OPENED",
    TICKET_CLOSE: "TICKET_CLOSE",
    TICKET_CANCELLED: "TICKET_CANCELLED",

    TICKET_CREATE: "CREATE",
    TICKET: 'TICKET',
    TICKET_DETAILS: 'TICKET_DETAILS',
    TICKET_NEW: 'TICKET_NEW_LABEL',
    TICKET_NEXT: 'TICKET_NEXT',
    VEHICLE: 'VEHICLE',
    TICKET_OPENED_LABEL: 'TICKET_OPENED_LABEL',
    TICKET_OPENED_LABEL_BY: 'TICKET_OPENED_LABEL_BY',
    TICKET_LAST_UPDATE: 'TICKET_LAST_UPDATE',
    TICKET_ASSIGNED: 'TICKET_ASSIGNED',
    TICKET_OPENED_FROM: 'TICKET_OPENED_FROM',
    TICKET_NEXTSTEP: 'TICKET_NEXTSTEP',
    TICKET_SHOW_HISTORY: 'TICKET_SHOW_HISTORY',
    TICKET_SHOW_ATTACH: 'TICKET_SHOW_ATTACH',
    TICKET_CONFIRM: 'TICKET_CONFIRM',
    TICKET_CONFIRM_ZERO: 'TICKET_CONFIRM_ZERO',
    TICKET_HISTORY: 'TICKET_HISTORY',
    TICKET_EXPIRED: 'TICKET_EXPIRED',
    TICKET_EXPIRE_TO: 'TICKET_EXPIRE_TO',
    TICKET_MAIL: 'TICKET_MAIL',
    TICKET_MAINTENANCE: 'TICKET_MAINTENANCE',

    TICKET_DASHBOARD: 'TICKET_DASHBOARD',
    TICKET_DASH_SERV_ORDER: 'TICKET_DASH_SERV_ORDER',
    TICKET_DASH_ORDER_NO :'TICKET_DASH_ORDER_NO',
    TICKET_DASH_COUNTRY: 'TICKET_DASH_COUNTRY',
    TICKET_DASH_FAIL_LOC: 'TICKET_DASH_FAIL_LOC',
    TICKET_DASH_FAIL_TIME: 'TICKET_DASH_FAIL_TIME',
    TICKET_DASH_RECEIVER: 'TICKET_DASH_RECEIVER',
    TICKET_DASH_STATUS: 'TICKET_DASH_STATUS',

    TICKET_DASH_DAILY_SERVICE_ORDER: 'TICKET_DASH_DAILY_SERVICE_ORDER',
	TICKET_DASH_MONTHLY_SERVICE_ORDER: 'TICKET_DASH_MONTHLY_SERVICE_ORDER',
	TICKET_DASH_ANNUALY_SERVICE_ORDER: 'TICKET_DASH_ANNUALY_SERVICE_ORDER',
	TICKET_DASH_PROCESSING: 'TICKET_DASH_PROCESSING',
	TICKET_DASH_EVALUATING: 'TICKET_DASH_EVALUATING',
	TICKET_DASH_AVERAGE_SERVICE_TIME: 'TICKET_DASH_AVERAGE_SERVICE_TIME',
    TICKET_DASH_DEALERS: 'TICKET_DASH_DEALERS',
    TICKET_DASH_WAREHOUSES: 'TICKET_DASH_WAREHOUSES',
    TICKET_DASH_WORKSHOPS: 'TICKET_DASH_WORKSHOPS',
	
    TICKET_DASH_USER_NAME: 'TICKET_DASH_USER_NAME',
    TICKET_DASH_USER_PROC: 'TICKET_DASH_USER_PROC',
    TICKET_DASH_USER_TOTAL: 'TICKET_DASH_USER_TOTAL',
    TICKET_DASH_USER_AVERAGE: 'TICKET_DASH_USER_AVERAGE',
    TICKET_DASH_USER_SAT_LEVEL: 'TICKET_DASH_USER_SAT_LEVEL',
   
    TICKET_PRODUCT_LINES: 'TICKET_PRODUCT_LINES',
    TICKET_24_COMPLETION_RATIO: 'TICKET_24_COMPLETION_RATIO',
    TICKET_3_COMPLETION_RATIO: 'TICKET_3_COMPLETION_RATIO',
    TICKET_EQUIPMENT_QTY: 'TICKET_EQUIPMENT_QTY',
    TICKET_PRECLAIM_NO: 'TICKET_PRECLAIM_NO',
    TICKET_PRECLAIM_ACCEPTED: 'TICKET_PRECLAIM_ACCEPTED', 
    TICKET_SATISFACTION_LEVEL: 'TICKET_SATISFACTION_LEVEL' ,
    TICKET_ASSETS_RIGHT: 'TICKET_ASSETS_RIGHT'  ,
    TICKET_ASSETS_LEFT: 'TICKET_ASSETS_LEFT' ,
    TICKET_SERVICE_DYNAMICS: 'TICKET_SERVICE_DYNAMICS'
   
   
}