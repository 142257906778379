import {
	ButtonBase,
	ListItem,
	makeStyles,
	Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

function getTo(label) {
	const baseUrl = '/app/tasks';
	return `${baseUrl}/${label?.id}`;
}

function getColor(storyboard) {
	var col = null;
	if (storyboard?.className && storyboard?.className !== '') col = '#2196f3';
	else col = '#ff9800';
	return col;
}

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0
	},
	content: {
		flexGrow: 1,
		height: 36,
		color: theme.palette.text.secondary,
		borderTopRightRadius: 18,
		borderBottomRightRadius: 18,
		paddingRight: 18,
		fontWeight: theme.typography.fontWeightRegular,
		'&:hover': {
			backgroundColor: theme.palette.action.hover
		}
	},
	active: {
		fontWeight: theme.typography.fontWeightMedium,
		backgroundColor: theme.palette.action.selected
	},
	label: {
		fontWeight: 'inherit',
		color: 'inherit'
	},
	labelRoot: {
		display: 'flex',
		alignItems: 'left',
		padding: theme.spacing(1, 0)
	},
	text: {
		fontWeight: 'inherit',
		marginLeft: 10,
		flexGrow: 1
	}
}));

function LabelItem({ label, ...rest }) {
	const classes = useStyles();

	const to = getTo(label);
	const color = getColor(label);

	return (
		<ListItem className={classes.root} {...rest}>
			<ButtonBase
				activeClassName={classes.active}
				component={RouterLink}
				to={to}
				className={classes.content}
			>
				<Typography
					className={classes.text}
					variant="body2"
					style={{ color: color }}
				>
					{label?.name}
				</Typography>
			</ButtonBase>
		</ListItem>
	);
}

LabelItem.propTypes = {
	label: PropTypes.object.isRequired,
	children: PropTypes.any
};

export default LabelItem;
